import api from '../../api/users/dashboard';
import cookies from '../../cookies';

export default {
  data(){
    return {
      allowAdmin: false,
      allowCuration: false,
      adminRows: [],
      curRows: [],
      userStatTotal: 0,
      userStatAssigned: 0,
      userStatUnderReview: 0,
      userStatApproved: 0,
      userStatRejected: 0,
      userStatRialApproved: 0,
      userStatRialUnderReview: 0,
      showDetails: false
    }
  },
  mounted(){
      this.allowAdmin = cookies.hasPermission('Curation Management');
      this.allowCuration = cookies.hasPermission('curation');
      this.refresh();
  },
  methods: {
    refresh(){
      this.adminRows = [];
      this.curRows = [];
      
      api.dashboard().then((res)=>{
          this.adminRows = res.data.statAdmin;
          this.curRows = res.data.statCuration; 
          this.calcSummary();
      }).catch( (err)=>{
        this.calcSummary();
          alert('Error connecting to server:\n' + err);
      });      
    },
    calcSummary(){
      console.log(this.curRows[0]);
      var nTotal = 0, nAssigned=0,nReview=0,nApproved=0,nRejected=0;
      var rApproved=0, rReview=0;
      for (var i=0;i<this.curRows.length;i++){
        nTotal++;
        if (this.curRows[i].status==0) nAssigned++;
        if (this.curRows[i].status==1){
          rReview += this.curRows[i].proposedRial;
          nReview++;
        }
        if (this.curRows[i].status==2){
          rReview += this.curRows[i].approvedRial;
          nApproved++;
        }
        if (this.curRows[i].status==3) nRejected++;

      }
      this.userStatTotal = nTotal;
      this.userStatApproved = nApproved;
      this.userStatAssigned = nAssigned;
      this.userStatRejected = nRejected;
      this.userStatUnderReview = nReview;
      this.userStatRialApproved = rApproved;
      this.userStatRialUnderReview = rReview;
    },
    toggleDetails(){
      this.showDetails=!this.showDetails;
    },
    getOutput(articleData){
      if (articleData.output == 0) return '-';
      if (articleData.output==1) return 'Set Entities:' + articleData.outputDesc;
      return 'Ignored:' + articleData.outputDesc;
    },
    getStatus(articleData){
      switch (articleData.status){
        case 0: return 'Assigned';
        case 1: return 'Under Review';
        case 2: return 'Approved';
        case 3: return 'Rejected';
      }
    }

  }
}